import { TrashIcon, TagIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { Bookmark } from '../types';

interface BookmarkCardProps {
  bookmark: Bookmark;
  onDelete: (id: string) => void;
}

export function BookmarkCard({ bookmark, onDelete }: BookmarkCardProps) {
  const formattedDate = new Date(bookmark.createdAt).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start">
        <a 
          href={bookmark.url} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-lg font-semibold text-blue-600 hover:text-blue-800 line-clamp-2"
        >
          {bookmark.title}
        </a>
        <button
          onClick={() => onDelete(bookmark.id)}
          className="text-gray-400 hover:text-red-500 p-1 hover:bg-red-50 rounded-full transition-colors"
          title="Delete bookmark"
        >
          <TrashIcon className="h-5 w-5" />
        </button>
      </div>
      
      <p className="text-gray-600 text-sm mt-2 line-clamp-2">
        {bookmark.description || 'No description provided'}
      </p>
      
      <div className="mt-3 space-y-2">
        <div className="flex items-center gap-2 text-sm text-gray-500">
          <CalendarIcon className="h-4 w-4" />
          <span>{formattedDate}</span>
        </div>

        <div className="flex items-center gap-2">
          <TagIcon className="h-4 w-4 text-gray-400 flex-shrink-0" />
          <div className="flex flex-wrap gap-1">
            {bookmark.tags.length > 0 ? (
              bookmark.tags.map((tag) => (
                <span
                  key={tag}
                  className="bg-blue-50 text-blue-700 text-xs px-2 py-1 rounded-full"
                >
                  {tag}
                </span>
              ))
            ) : (
              <span className="text-sm text-gray-400">No tags</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}