import { Link } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { TrialBanner } from './TrialBanner';

export function Header() {
  return (
    <>
      <TrialBanner />
      <header className="bg-white shadow-sm sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/dashboard" className="flex items-center gap-2">
              <svg className="w-8 h-8 text-blue-600" viewBox="0 0 24 24" fill="currentColor">
                <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z"/>
              </svg>
              <h1 className="text-2xl font-bold text-gray-900">PushPin Digital</h1>
            </Link>
            <Link
              to="/profile"
              className="p-2 text-gray-500 hover:text-gray-700 rounded-full"
              title="Profile"
            >
              <UserCircleIcon className="w-6 h-6" />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}