import { Link } from 'react-router-dom';
import { useAuth } from '../store/authStore';

export function LandingPage() {
  const { hasAccess } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            PushPin Digital
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Your cross-platform bookmark manager
          </p>
          {hasAccess ? (
            <Link
              to="/dashboard"
              className="inline-block bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700"
            >
              Go to Dashboard
            </Link>
          ) : (
            <div className="space-y-4">
              <div className="space-x-4">
                <Link
                  to="/signup"
                  className="inline-block bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700"
                >
                  Start Free Trial
                </Link>
                <Link
                  to="/login"
                  className="inline-block bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-gray-50 border border-blue-600"
                >
                  Sign In
                </Link>
              </div>
              <div className="text-sm text-gray-500">
                10 days free trial, then one-time purchase
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}