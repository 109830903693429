import { useState } from 'react';
import { Header } from './Header';
import { AddBookmark } from './AddBookmark';
import { BookmarkCard } from './BookmarkCard';
import { Stats } from './Stats';
import { useBookmarkStore } from '../store/bookmarkStore';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import { Bookmark } from '../types';

export function Dashboard() {
  const [searchQuery, setSearchQuery] = useState('');
  const { bookmarks, addBookmark, deleteBookmark, searchBookmarks } = useBookmarkStore();

  const handleAddBookmark = (bookmark: Omit<Bookmark, 'id'>) => {
    addBookmark(bookmark);
    toast.success('Bookmark added successfully!');
  };

  const handleDeleteBookmark = (id: string) => {
    deleteBookmark(id);
    toast.success('Bookmark deleted successfully!');
  };

  const filteredBookmarks = searchQuery ? searchBookmarks(searchQuery) : bookmarks;

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-end mb-8">
          <div className="relative">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="Search bookmarks..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 w-64"
            />
          </div>
        </div>

        <Stats bookmarks={bookmarks} />
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredBookmarks.map((bookmark) => (
            <BookmarkCard
              key={bookmark.id}
              bookmark={bookmark}
              onDelete={handleDeleteBookmark}
            />
          ))}
        </div>
        
        {filteredBookmarks.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm border border-gray-100">
            <div className="inline-block p-3 bg-blue-100 rounded-full mb-4">
              <svg className="w-8 h-8 text-blue-600" viewBox="0 0 24 24" fill="currentColor">
                <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z"/>
              </svg>
            </div>
            <p className="text-xl font-semibold text-gray-900 mb-2">
              {searchQuery ? 'No bookmarks found' : 'No bookmarks yet'}
            </p>
            <p className="text-gray-500">
              {searchQuery 
                ? 'Try adjusting your search terms or clear the search'
                : 'Click the + button to add your first bookmark'}
            </p>
          </div>
        )}

        <AddBookmark onAdd={handleAddBookmark} />
      </main>
    </div>
  );
}