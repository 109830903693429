import { 
  BookmarkIcon, 
  TagIcon, 
  ClockIcon, 
  ChartBarIcon 
} from '@heroicons/react/24/outline';
import { Bookmark } from '../types';

interface StatsProps {
  bookmarks: Bookmark[];
}

export function Stats({ bookmarks }: StatsProps) {
  const getTotalTags = () => {
    const uniqueTags = new Set(bookmarks.flatMap(b => b.tags));
    return uniqueTags.size;
  };

  const getAddedThisWeek = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return bookmarks.filter(b => new Date(b.createdAt) > oneWeekAgo).length;
  };

  const stats = [
    {
      name: 'Total Bookmarks',
      value: bookmarks.length,
      icon: BookmarkIcon,
      color: 'bg-blue-500',
    },
    {
      name: 'Unique Tags',
      value: getTotalTags(),
      icon: TagIcon,
      color: 'bg-green-500',
    },
    {
      name: 'Added This Week',
      value: getAddedThisWeek(),
      icon: ClockIcon,
      color: 'bg-purple-500',
    },
    {
      name: 'Average Tags/Bookmark',
      value: bookmarks.length 
        ? (bookmarks.reduce((acc, b) => acc + b.tags.length, 0) / bookmarks.length).toFixed(1)
        : '0',
      icon: ChartBarIcon,
      color: 'bg-orange-500',
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="bg-white p-6 rounded-lg shadow-sm border border-gray-100"
        >
          <div className="flex items-center">
            <div className={`${stat.color} p-2 rounded-lg`}>
              <stat.icon className="h-6 w-6 text-white" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">{stat.name}</p>
              <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}