import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { Header } from './Header';
import { toast } from 'react-hot-toast';

export function Profile() {
  const { email, logout } = useAuthStore();
  const [isConfirming, setIsConfirming] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }
    logout();
    toast.success('Logged out successfully');
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-6">Profile Settings</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1 text-gray-900">{email}</div>
            </div>

            <div className="pt-4">
              <button
                onClick={handleLogout}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                {isConfirming ? 'Click again to confirm' : 'Logout'}
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}