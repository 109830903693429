import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface AuthState {
  email: string | null;
  passwordHash: string | null;
  trialStartDate: string | null;
  isPaid: boolean;
  hasAccess: boolean;
  daysRemaining: number;
  setCredentials: (email: string, password: string) => void;
  verifyPassword: (password: string) => boolean;
  startTrial: () => void;
  setPaid: () => void;
  logout: () => void;
}

// Simple hash function - Note: This is not secure for production!
const hashPassword = (password: string): string => {
  return btoa(password); // Base64 encoding for demo purposes
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      email: null,
      passwordHash: null,
      trialStartDate: null,
      isPaid: false,
      hasAccess: false,
      daysRemaining: 0,

      setCredentials: (email, password) => set({
        email,
        passwordHash: hashPassword(password),
        hasAccess: false,
      }),

      verifyPassword: (password) => {
        const state = get();
        return state.passwordHash === hashPassword(password);
      },

      startTrial: () => {
        const trialStartDate = new Date().toISOString();
        set({
          trialStartDate,
          hasAccess: true,
          daysRemaining: 10,
        });
      },

      setPaid: () => set({ isPaid: true, hasAccess: true }),

      logout: () => set({
        email: null,
        passwordHash: null,
        trialStartDate: null,
        isPaid: false,
        hasAccess: false,
        daysRemaining: 0,
      }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            hasAccess: Boolean(persistedState.isPaid || persistedState.trialStartDate),
            passwordHash: null,
          };
        }
        return persistedState;
      },
    }
  )
);

export function useAuth() {
  const { trialStartDate, isPaid, hasAccess } = useAuthStore();

  const getDaysRemaining = () => {
    if (!trialStartDate) return 0;
    if (isPaid) return 0;

    const trial = new Date(trialStartDate);
    const now = new Date();
    const diffTime = trial.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.max(0, diffDays + 10);
  };

  return {
    daysRemaining: getDaysRemaining(),
    isPaid,
    hasAccess,
  };
}