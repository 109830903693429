import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Bookmark } from '../types';

interface BookmarkStore {
  bookmarks: Bookmark[];
  addBookmark: (bookmark: Omit<Bookmark, 'id'>) => void;
  deleteBookmark: (id: string) => void;
  searchBookmarks: (query: string) => Bookmark[];
}

export const useBookmarkStore = create<BookmarkStore>()(
  persist(
    (set, get) => ({
      bookmarks: [],
      addBookmark: (bookmark) => set((state) => ({
        bookmarks: [
          {
            ...bookmark,
            id: crypto.randomUUID(),
          },
          ...state.bookmarks,
        ],
      })),
      deleteBookmark: (id) => set((state) => ({
        bookmarks: state.bookmarks.filter((bookmark) => bookmark.id !== id),
      })),
      searchBookmarks: (query) => {
        const { bookmarks } = get();
        const searchTerm = query.toLowerCase();
        return bookmarks.filter((bookmark) => 
          bookmark.title.toLowerCase().includes(searchTerm) ||
          bookmark.description.toLowerCase().includes(searchTerm) ||
          bookmark.tags.some((tag) => tag.toLowerCase().includes(searchTerm))
        );
      },
    }),
    {
      name: 'bookmarks-storage',
      version: 1,
      migrate: (state: any) => {
        return {
          bookmarks: state?.bookmarks || [],
        };
      },
    }
  )
);