import { useAuth } from '../store/authStore';

export function TrialBanner() {
  const { daysRemaining, isPaid } = useAuth();

  if (isPaid) return null;

  return (
    <div className="bg-blue-600 text-white px-4 py-2 text-center text-sm">
      {daysRemaining > 0 ? (
        <>
          Trial period: {daysRemaining} days remaining.{' '}
          <a
            href="https://buy.stripe.com/7sIeVrelS9Bu7WoeUU"
            className="underline font-medium hover:text-blue-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchase Now
          </a>
        </>
      ) : (
        <>
          Trial period ended.{' '}
          <a
            href="https://buy.stripe.com/7sIeVrelS9Bu7WoeUU"
            className="underline font-medium hover:text-blue-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchase Now
          </a>
          {' '}to continue using PushPin Digital
        </>
      )}
    </div>
  );
}